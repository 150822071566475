import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_form_item = _resolveComponent("el-form-item")
  const _component_el_form = _resolveComponent("el-form")
  const _component_el_collapse_item = _resolveComponent("el-collapse-item")
  const _component_el_input = _resolveComponent("el-input")
  const _component_el_date_picker = _resolveComponent("el-date-picker")
  const _component_el_option = _resolveComponent("el-option")
  const _component_el_select = _resolveComponent("el-select")
  const _component_FileuploadListV1 = _resolveComponent("FileuploadListV1")
  const _component_el_table_column = _resolveComponent("el-table-column")
  const _component_el_table = _resolveComponent("el-table")
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")
  const _component_el_tabs = _resolveComponent("el-tabs")
  const _component_el_collapse = _resolveComponent("el-collapse")
  const _component_FundRunSubmit = _resolveComponent("FundRunSubmit")
  const _component_el_card = _resolveComponent("el-card")
  const _component_BiddingManage = _resolveComponent("BiddingManage")
  const _component_ContractMange = _resolveComponent("ContractMange")
  const _component_FirstCheckMange = _resolveComponent("FirstCheckMange")
  const _component_ProjectStartMange = _resolveComponent("ProjectStartMange")
  const _component_el_dialog = _resolveComponent("el-dialog")
  const _directive_loading = _resolveDirective("loading")

  return (_openBlock(), _createBlock(_component_el_dialog, {
    modelValue: _ctx.visible,
    "onUpdate:modelValue": _cache[39] || (_cache[39] = ($event: any) => (_ctx.visible = $event)),
    title: "文物保护项目进展情况登记表",
    width: "90%"
  }, {
    default: _withCtx(() => [
      _withDirectives(_createVNode(_component_el_card, null, {
        default: _withCtx(() => [
          _createVNode(_component_el_tabs, {
            "tab-position": "left",
            modelValue: _ctx.activeName,
            "onUpdate:modelValue": _cache[34] || (_cache[34] = ($event: any) => (_ctx.activeName = $event))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_tab_pane, {
                label: "项目进度情况",
                name: "first"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_collapse, {
                    modelValue: _ctx.activeNames,
                    "onUpdate:modelValue": _cache[33] || (_cache[33] = ($event: any) => (_ctx.activeNames = $event))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_collapse_item, {
                        name: "1",
                        title: "基本信息"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_form, {
                            model: _ctx.form,
                            "label-width": "200px",
                            disabled: true
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_form_item, { label: "市级" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.form.city_Name), 1)
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_el_form_item, { label: "区县" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.form.country_Name), 1)
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_el_form_item, { label: "不可移动文物" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.form.heritageUnit_Name), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }, 8, ["model"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_el_collapse_item, {
                        name: "2",
                        title: "计划书"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_form, {
                            model: _ctx.form,
                            "label-width": "200px",
                            disabled: true
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_form_item, { label: "计划书批复名称" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_el_input, {
                                    modelValue: _ctx.form.setupDoc_Title,
                                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.form.setupDoc_Title = $event))
                                  }, null, 8, ["modelValue"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_el_form_item, { label: "计划书日期" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_el_date_picker, {
                                    modelValue: _ctx.form.setupDoc_Date,
                                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.form.setupDoc_Date = $event))
                                  }, null, 8, ["modelValue"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_el_form_item, { label: "业主单位名称" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_el_input, {
                                    modelValue: _ctx.form.project_Unit_Name,
                                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.form.project_Unit_Name = $event)),
                                    placeholder: "请填写"
                                  }, null, 8, ["modelValue"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_el_form_item, { label: "批复文号" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_el_input, {
                                    modelValue: _ctx.form.setupDoc,
                                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (_ctx.form.setupDoc = $event))
                                  }, null, 8, ["modelValue"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }, 8, ["model"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_el_collapse_item, {
                        name: "3",
                        title: "技术方案情况"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_form, {
                            model: _ctx.form,
                            "label-width": "200px",
                            disabled: true
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_form_item, { label: "技术方案进展" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_el_select, {
                                    modelValue: _ctx.form.proposalDoc_Status,
                                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (_ctx.form.proposalDoc_Status = $event))
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_el_option, {
                                        label: "未编制",
                                        value: "未编制"
                                      }),
                                      _createVNode(_component_el_option, {
                                        label: "技术方案编制中",
                                        value: "技术方案编制中"
                                      }),
                                      _createVNode(_component_el_option, {
                                        label: "方案审批中",
                                        value: "方案审批中"
                                      }),
                                      _createVNode(_component_el_option, {
                                        label: "获得方案批复",
                                        value: "获得方案批复"
                                      })
                                    ]),
                                    _: 1
                                  }, 8, ["modelValue"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_el_form_item, { label: "技术方案名称" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_el_input, {
                                    modelValue: _ctx.form.proposalDoc_Title,
                                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (_ctx.form.proposalDoc_Title = $event))
                                  }, null, 8, ["modelValue"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_el_form_item, { label: "方案编制单位" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_el_input, {
                                    modelValue: _ctx.form.proposalDoc_DesignUnit,
                                    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (_ctx.form.proposalDoc_DesignUnit = $event))
                                  }, null, 8, ["modelValue"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_el_form_item, { label: "方案编制日期" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_el_date_picker, {
                                    modelValue: _ctx.form.proposalDoc_WriteDate,
                                    "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => (_ctx.form.proposalDoc_WriteDate = $event))
                                  }, null, 8, ["modelValue"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_el_form_item, { label: "方案批复日期" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_el_date_picker, {
                                    modelValue: _ctx.form.proposalDoc_AccessDate,
                                    "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => (_ctx.form.proposalDoc_AccessDate = $event))
                                  }, null, 8, ["modelValue"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_el_form_item, { label: "方案批复文号" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_el_input, {
                                    modelValue: _ctx.form.proposalDoc,
                                    "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => (_ctx.form.proposalDoc = $event))
                                  }, null, 8, ["modelValue"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }, 8, ["model"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_el_collapse_item, {
                        name: "4",
                        title: "工程实施情况"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_form, {
                            model: _ctx.form,
                            "label-width": "200px",
                            disabled: true
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_form_item, { label: "项目编号" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_el_input, {
                                    modelValue: _ctx.form.project_Code,
                                    "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => (_ctx.form.project_Code = $event))
                                  }, null, 8, ["modelValue"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_el_form_item, { label: "项目名称" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_el_input, {
                                    modelValue: _ctx.form.project_Name,
                                    "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => (_ctx.form.project_Name = $event))
                                  }, null, 8, ["modelValue"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_el_form_item, { label: "文物类别" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_el_input, {
                                    modelValue: _ctx.form.heritage_Type,
                                    "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => (_ctx.form.heritage_Type = $event))
                                  }, null, 8, ["modelValue"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_el_form_item, { label: "项目类别" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_el_input, {
                                    modelValue: _ctx.form.project_Type,
                                    "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => (_ctx.form.project_Type = $event))
                                  }, null, 8, ["modelValue"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_el_form_item, { label: "项目负责人" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_el_input, {
                                    modelValue: _ctx.form.project_Manager,
                                    "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => (_ctx.form.project_Manager = $event))
                                  }, null, 8, ["modelValue"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_el_form_item, { label: "职务" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_el_input, {
                                    modelValue: _ctx.form.project_Manager_Job,
                                    "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => (_ctx.form.project_Manager_Job = $event))
                                  }, null, 8, ["modelValue"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_el_form_item, { label: "联系电话" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_el_input, {
                                    modelValue: _ctx.form.project_Manager_Phone,
                                    "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => (_ctx.form.project_Manager_Phone = $event))
                                  }, null, 8, ["modelValue"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_el_form_item, { label: "邮箱" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_el_input, {
                                    modelValue: _ctx.form.project_Manager_Email,
                                    "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => (_ctx.form.project_Manager_Email = $event))
                                  }, null, 8, ["modelValue"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_el_form_item, { label: "财务负责人" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_el_input, {
                                    modelValue: _ctx.form.project_FundManager,
                                    "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => (_ctx.form.project_FundManager = $event))
                                  }, null, 8, ["modelValue"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_el_form_item, { label: "职务" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_el_input, {
                                    modelValue: _ctx.form.project_FundManager_Job,
                                    "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => (_ctx.form.project_FundManager_Job = $event))
                                  }, null, 8, ["modelValue"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_el_form_item, { label: "联系电话" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_el_input, {
                                    modelValue: _ctx.form.project_FundManager_Phone,
                                    "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => (_ctx.form.project_FundManager_Phone = $event))
                                  }, null, 8, ["modelValue"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_el_form_item, { label: "邮箱" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_el_input, {
                                    modelValue: _ctx.form.project_FundManager_Email,
                                    "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => (_ctx.form.project_FundManager_Email = $event))
                                  }, null, 8, ["modelValue"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_el_form_item, { label: "进展状态" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_el_select, {
                                    modelValue: _ctx.form.project_DoStatus,
                                    "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => (_ctx.form.project_DoStatus = $event))
                                  }, {
                                    default: _withCtx(() => [
                                      (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.fundWatchProjectDoStatusDic, (item) => {
                                        return (_openBlock(), _createBlock(_component_el_option, {
                                          key: item,
                                          label: item,
                                          value: item
                                        }, null, 8, ["label", "value"]))
                                      }), 128))
                                    ]),
                                    _: 1
                                  }, 8, ["modelValue"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_el_form_item, { label: "进展描述" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_el_input, {
                                    type: "textarea",
                                    modelValue: _ctx.form.questionDetail,
                                    "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => (_ctx.form.questionDetail = $event))
                                  }, null, 8, ["modelValue"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_el_form_item, { label: "工程经费(万元)" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.form.project_Fund_Amount), 1)
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_el_form_item, { label: "经费来源" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_el_select, {
                                    modelValue: _ctx.form.project_Fund_Source,
                                    "onUpdate:modelValue": _cache[25] || (_cache[25] = ($event: any) => (_ctx.form.project_Fund_Source = $event))
                                  }, {
                                    default: _withCtx(() => [
                                      (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.fundWatchSourceDic, (item) => {
                                        return (_openBlock(), _createBlock(_component_el_option, {
                                          label: item,
                                          value: item,
                                          key: item
                                        }, null, 8, ["label", "value"]))
                                      }), 128))
                                    ]),
                                    _: 1
                                  }, 8, ["modelValue"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_el_form_item, { label: "施工单位" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_el_input, {
                                    modelValue: _ctx.form.project_DoUnit,
                                    "onUpdate:modelValue": _cache[26] || (_cache[26] = ($event: any) => (_ctx.form.project_DoUnit = $event))
                                  }, null, 8, ["modelValue"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_el_form_item, { label: "设计单位" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_el_input, {
                                    modelValue: _ctx.form.project_DesignUnit,
                                    "onUpdate:modelValue": _cache[27] || (_cache[27] = ($event: any) => (_ctx.form.project_DesignUnit = $event))
                                  }, null, 8, ["modelValue"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_el_form_item, { label: "监理单位" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_el_input, {
                                    modelValue: _ctx.form.project_WatchUnit,
                                    "onUpdate:modelValue": _cache[28] || (_cache[28] = ($event: any) => (_ctx.form.project_WatchUnit = $event))
                                  }, null, 8, ["modelValue"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_el_form_item, { label: "管理单位" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_el_input, {
                                    modelValue: _ctx.form.project_ManageUnit,
                                    "onUpdate:modelValue": _cache[29] || (_cache[29] = ($event: any) => (_ctx.form.project_ManageUnit = $event))
                                  }, null, 8, ["modelValue"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_el_form_item, { label: "开工日期" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_el_date_picker, {
                                    modelValue: _ctx.form.project_StartDate,
                                    "onUpdate:modelValue": _cache[30] || (_cache[30] = ($event: any) => (_ctx.form.project_StartDate = $event))
                                  }, null, 8, ["modelValue"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_el_form_item, { label: "完工日期" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_el_date_picker, {
                                    modelValue: _ctx.form.project_EndDate,
                                    "onUpdate:modelValue": _cache[31] || (_cache[31] = ($event: any) => (_ctx.form.project_EndDate = $event))
                                  }, null, 8, ["modelValue"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_el_form_item, {
                                label: "相关附件(图片、文档、资料)",
                                prop: "attachList"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_FileuploadListV1, {
                                    files: _ctx.form.attachList,
                                    "onUpdate:files": _cache[32] || (_cache[32] = ($event: any) => (_ctx.form.attachList = $event)),
                                    accpet: ".zip,.rar,.pdf,.wps,.ofd,.doc,.docx,.jpg,.png,.gif",
                                    urlKey: "filePath",
                                    nameKey: "fileName"
                                  }, null, 8, ["files"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_el_tabs, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_el_tab_pane, { label: "招标信息" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_el_table, {
                                        data: _ctx.form.biddingInfos
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_el_table_column, {
                                            label: "中标单位",
                                            prop: "bidedUnitName"
                                          }),
                                          _createVNode(_component_el_table_column, {
                                            label: "招标类型",
                                            prop: "biddingType"
                                          }),
                                          _createVNode(_component_el_table_column, {
                                            label: "招标方式",
                                            prop: "biddingMethod"
                                          }),
                                          _createVNode(_component_el_table_column, {
                                            label: "中标日期",
                                            prop: "bidedDate"
                                          }, {
                                            default: _withCtx(({ row: item }) => [
                                              _createTextVNode(_toDisplayString(_ctx.parseTime(item.bidedDate, "{yyyy}-{mm}-{dd}")), 1)
                                            ]),
                                            _: 1
                                          }),
                                          _createVNode(_component_el_table_column, {
                                            label: "中标金额（万元）",
                                            prop: "bidedAmount"
                                          }),
                                          _createVNode(_component_el_table_column, {
                                            label: "中标通知书编号",
                                            prop: "bidedFileNo"
                                          }),
                                          _createVNode(_component_el_table_column, {
                                            label: "附件",
                                            prop: "attach"
                                          }, {
                                            default: _withCtx(({ row: item }) => [
                                              (item.attach)
                                                ? (_openBlock(), _createBlock("a", {
                                                    key: 0,
                                                    download: item.attach.fileName,
                                                    href: item.attach.filePath,
                                                    target: "_blank"
                                                  }, _toDisplayString(item.attach.fileName), 9, ["download", "href"]))
                                                : _createCommentVNode("", true)
                                            ]),
                                            _: 1
                                          }),
                                          _createVNode(_component_el_table_column, {
                                            label: "提交日期",
                                            prop: "createTime"
                                          }, {
                                            default: _withCtx(({ row: item }) => [
                                              _createTextVNode(_toDisplayString(_ctx.parseTime(item.createTime, "{yyyy}-{mm}-{dd}")), 1)
                                            ]),
                                            _: 1
                                          })
                                        ]),
                                        _: 1
                                      }, 8, ["data"])
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_el_tab_pane, { label: "工程合同信息" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_el_table, {
                                        data: _ctx.form.contractInfos
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_el_table_column, {
                                            label: "合同类别",
                                            prop: "contractType"
                                          }),
                                          _createVNode(_component_el_table_column, {
                                            label: "合同登记编号",
                                            prop: "contractNo"
                                          }),
                                          _createVNode(_component_el_table_column, {
                                            label: "合同金额（万元）",
                                            prop: "contractAmount"
                                          }),
                                          _createVNode(_component_el_table_column, {
                                            label: "发包单位名称",
                                            prop: "contractUnit"
                                          }),
                                          _createVNode(_component_el_table_column, {
                                            label: "承包单位名称",
                                            prop: "contractor"
                                          }),
                                          _createVNode(_component_el_table_column, {
                                            label: "附件",
                                            prop: "attach"
                                          }, {
                                            default: _withCtx(({ row: item }) => [
                                              (item.attach)
                                                ? (_openBlock(), _createBlock("a", {
                                                    key: 0,
                                                    download: item.attach.fileName,
                                                    href: item.attach.filePath,
                                                    target: "_blank"
                                                  }, _toDisplayString(item.attach.fileName), 9, ["download", "href"]))
                                                : _createCommentVNode("", true)
                                            ]),
                                            _: 1
                                          }),
                                          _createVNode(_component_el_table_column, {
                                            label: "提交日期",
                                            prop: "createTime"
                                          }, {
                                            default: _withCtx(({ row: item }) => [
                                              _createTextVNode(_toDisplayString(_ctx.parseTime(item.createTime, "{yyyy}-{mm}-{dd}")), 1)
                                            ]),
                                            _: 1
                                          })
                                        ]),
                                        _: 1
                                      }, 8, ["data"])
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_el_tab_pane, { label: "开工信息" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_el_table, {
                                        data: _ctx.form.projectStartInfos
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_el_table_column, {
                                            label: "施工许可编号",
                                            prop: "permitNo"
                                          }),
                                          _createVNode(_component_el_table_column, {
                                            label: "合同金额（万元）",
                                            prop: "amount"
                                          }),
                                          _createVNode(_component_el_table_column, {
                                            label: "面积（平方米）",
                                            prop: "areaSize"
                                          }),
                                          _createVNode(_component_el_table_column, {
                                            label: "发证日期",
                                            prop: "giveLicenseTime"
                                          }, {
                                            default: _withCtx(({ row: item }) => [
                                              _createTextVNode(_toDisplayString(_ctx.parseTime(item.giveLicenseTime, "{yyyy}-{mm}-{dd}")), 1)
                                            ]),
                                            _: 1
                                          }),
                                          _createVNode(_component_el_table_column, {
                                            label: "记录登记日期",
                                            prop: "logTime"
                                          }, {
                                            default: _withCtx(({ row: item }) => [
                                              _createTextVNode(_toDisplayString(_ctx.parseTime(item.logTime, "{yyyy}-{mm}-{dd}")), 1)
                                            ]),
                                            _: 1
                                          }),
                                          _createVNode(_component_el_table_column, {
                                            label: "附件",
                                            prop: "attach"
                                          }, {
                                            default: _withCtx(({ row: item }) => [
                                              (item.attach)
                                                ? (_openBlock(), _createBlock("a", {
                                                    key: 0,
                                                    download: item.attach.fileName,
                                                    href: item.attach.filePath,
                                                    target: "_blank"
                                                  }, _toDisplayString(item.attach.fileName), 9, ["download", "href"]))
                                                : _createCommentVNode("", true)
                                            ]),
                                            _: 1
                                          }),
                                          _createVNode(_component_el_table_column, {
                                            label: "提交日期",
                                            prop: "createTime"
                                          }, {
                                            default: _withCtx(({ row: item }) => [
                                              _createTextVNode(_toDisplayString(_ctx.parseTime(item.createTime, "{yyyy}-{mm}-{dd}")), 1)
                                            ]),
                                            _: 1
                                          })
                                        ]),
                                        _: 1
                                      }, 8, ["data"])
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_el_tab_pane, { label: "自查初验信息" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_el_table, {
                                        data: _ctx.form.firstCheckInfos
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_el_table_column, {
                                            label: "初验日期",
                                            prop: "checkTime"
                                          }, {
                                            default: _withCtx(({ row: item }) => [
                                              _createTextVNode(_toDisplayString(_ctx.parseTime(item.checkTime, "{yyyy}-{mm}-{dd}")), 1)
                                            ]),
                                            _: 1
                                          }),
                                          _createVNode(_component_el_table_column, {
                                            label: "初验结论",
                                            prop: "checkResult"
                                          }),
                                          _createVNode(_component_el_table_column, { label: "专家意见" }),
                                          _createVNode(_component_el_table_column, { label: "预决算报告" }),
                                          _createVNode(_component_el_table_column, { label: "监理报告" }),
                                          _createVNode(_component_el_table_column, {
                                            label: "其他",
                                            prop: "checkTime"
                                          }),
                                          _createVNode(_component_el_table_column, {
                                            label: "附件",
                                            prop: "attach"
                                          }, {
                                            default: _withCtx(({ row: item }) => [
                                              _createVNode("p", null, [
                                                (item.expertAdvise)
                                                  ? (_openBlock(), _createBlock("a", {
                                                      key: 0,
                                                      download: item.expertAdvise.fileName,
                                                      href: item.expertAdvise.filePath,
                                                      target: "_blank"
                                                    }, _toDisplayString(item.expertAdvise.fileName), 9, ["download", "href"]))
                                                  : _createCommentVNode("", true)
                                              ]),
                                              _createVNode("p", null, [
                                                (item.amountReport)
                                                  ? (_openBlock(), _createBlock("a", {
                                                      key: 0,
                                                      download: item.amountReport.fileName,
                                                      href: item.amountReport.filePath,
                                                      target: "_blank"
                                                    }, _toDisplayString(item.amountReport.fileName), 9, ["download", "href"]))
                                                  : _createCommentVNode("", true)
                                              ]),
                                              _createVNode("p", null, [
                                                (item.watchReport)
                                                  ? (_openBlock(), _createBlock("a", {
                                                      key: 0,
                                                      download: item.watchReport.fileName,
                                                      href: item.watchReport.filePath,
                                                      target: "_blank"
                                                    }, _toDisplayString(item.watchReport.fileName), 9, ["download", "href"]))
                                                  : _createCommentVNode("", true)
                                              ]),
                                              _createVNode("p", null, [
                                                (item.elseFile)
                                                  ? (_openBlock(), _createBlock("a", {
                                                      key: 0,
                                                      download: item.elseFile.fileName,
                                                      href: item.elseFile.filePath,
                                                      target: "_blank"
                                                    }, _toDisplayString(item.elseFile.fileName), 9, ["download", "href"]))
                                                  : _createCommentVNode("", true)
                                              ])
                                            ]),
                                            _: 1
                                          }),
                                          _createVNode(_component_el_table_column, { label: "提交日期" }, {
                                            default: _withCtx(({ row: item }) => [
                                              _createTextVNode(_toDisplayString(_ctx.parseTime(item.createTime, "{yyyy}-{mm}-{dd}")), 1)
                                            ]),
                                            _: 1
                                          })
                                        ]),
                                        _: 1
                                      }, 8, ["data"])
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_el_tab_pane, { label: "四方验评" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_el_table, {
                                        data: _ctx.form.unionCheckInfos
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_el_table_column, {
                                            label: "验评日期",
                                            prop: "checkTime"
                                          }, {
                                            default: _withCtx(({ row: item }) => [
                                              _createTextVNode(_toDisplayString(_ctx.parseTime(item.checkTime, "{yyyy}-{mm}-{dd}")), 1)
                                            ]),
                                            _: 1
                                          }),
                                          _createVNode(_component_el_table_column, {
                                            label: "验评结论",
                                            prop: "checkResult"
                                          }),
                                          _createVNode(_component_el_table_column, {
                                            label: "设计单位",
                                            prop: "designUnit"
                                          }),
                                          _createVNode(_component_el_table_column, {
                                            label: "施工单位",
                                            prop: "doUnit"
                                          }),
                                          _createVNode(_component_el_table_column, {
                                            label: "监理单位",
                                            prop: "watchUnit"
                                          }),
                                          _createVNode(_component_el_table_column, {
                                            label: "管理单位",
                                            prop: "ownerUnit"
                                          }),
                                          _createVNode(_component_el_table_column, {
                                            label: "验评报告",
                                            prop: "file"
                                          }, {
                                            default: _withCtx(({ row: item }) => [
                                              _createVNode("p", null, [
                                                (item.file)
                                                  ? (_openBlock(), _createBlock("a", {
                                                      key: 0,
                                                      download: item.file.fileName,
                                                      href: item.file.filePath,
                                                      target: "_blank"
                                                    }, _toDisplayString(item.file.fileName), 9, ["download", "href"]))
                                                  : _createCommentVNode("", true)
                                              ])
                                            ]),
                                            _: 1
                                          }),
                                          _createVNode(_component_el_table_column, { label: "提交日期" }, {
                                            default: _withCtx(({ row: item }) => [
                                              _createTextVNode(_toDisplayString(_ctx.parseTime(item.createTime, "{yyyy}-{mm}-{dd}")), 1)
                                            ]),
                                            _: 1
                                          })
                                        ]),
                                        _: 1
                                      }, 8, ["data"])
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }, 8, ["model"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_el_tab_pane, {
                label: "资金支出",
                name: "second"
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.fundWatches, (item, i) => {
                    return (_openBlock(), _createBlock(_component_FundRunSubmit, {
                      disabled: true,
                      modelValue: _ctx.fundWatches[i],
                      "onUpdate:modelValue": ($event: any) => (_ctx.fundWatches[i] = $event),
                      id: item.id,
                      key: item.id,
                      style: {"width":"100%"}
                    }, null, 8, ["modelValue", "onUpdate:modelValue", "id"]))
                  }), 128))
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]),
        _: 1
      }, 512), [
        [_directive_loading, _ctx.isLoading]
      ]),
      (_ctx.biddingManageSetting.visible)
        ? (_openBlock(), _createBlock(_component_BiddingManage, {
            key: 0,
            setting: _ctx.biddingManageSetting,
            onChange: _cache[35] || (_cache[35] = (arg) => _ctx.onListManged(_ctx.form.biddingInfos, arg))
          }, null, 8, ["setting"]))
        : _createCommentVNode("", true),
      (_ctx.contractMangeSetting.visible)
        ? (_openBlock(), _createBlock(_component_ContractMange, {
            key: 1,
            setting: _ctx.contractMangeSetting,
            onChange: _cache[36] || (_cache[36] = (arg) => _ctx.onListManged(_ctx.form.contractInfos, arg))
          }, null, 8, ["setting"]))
        : _createCommentVNode("", true),
      (_ctx.firstCheckSetting.visible)
        ? (_openBlock(), _createBlock(_component_FirstCheckMange, {
            key: 2,
            setting: _ctx.firstCheckSetting,
            onChange: _cache[37] || (_cache[37] = (arg) => _ctx.onListManged(_ctx.form.firstCheckInfos, arg))
          }, null, 8, ["setting"]))
        : _createCommentVNode("", true),
      (_ctx.firstCheckSetting.visible)
        ? (_openBlock(), _createBlock(_component_ProjectStartMange, {
            key: 3,
            setting: _ctx.projectStartMangeSetting,
            onChange: _cache[38] || (_cache[38] = (arg) => _ctx.onListManged(_ctx.form.projectStartInfos, arg))
          }, null, 8, ["setting"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["modelValue"]))
}