import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_statusSubmitEdit = _resolveComponent("statusSubmitEdit")
  const _component_statusSubmitView = _resolveComponent("statusSubmitView")

  return (_openBlock(), _createBlock(_Fragment, null, [
    (_ctx.action == 'edit' && _ctx.setting.visible)
      ? (_openBlock(), _createBlock(_component_statusSubmitEdit, {
          key: 0,
          setting: _ctx.setting,
          action: _ctx.action,
          onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('change')))
        }, null, 8, ["setting", "action"]))
      : _createCommentVNode("", true),
    (_ctx.action == 'view' && _ctx.setting.visible)
      ? (_openBlock(), _createBlock(_component_statusSubmitView, {
          key: 1,
          action: _ctx.action,
          setting: _ctx.setting
        }, null, 8, ["action", "setting"]))
      : _createCommentVNode("", true)
  ], 64))
}