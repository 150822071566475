
import SingleFileupload from "@/components/singleFileupload";
import {
  inject,
  defineComponent,
  toRefs,
  ref,
  reactive,
  watch,
  Ref,
  PropType,
  computed,
  toRef,
  onMounted,
} from "vue";
import settings from "@/settings";
import {
  numberRulesNoRequired,
  inputChangeEmptyToNull,
} from "@/model/fundFormRules.ts";
export default defineComponent({
  components: { SingleFileupload },
  props: {
    setting: { type: Object },
  },
  emits: ["change"],
  setup(props, { emit }) {
    const rules = {
      contractAmount: numberRulesNoRequired,
    };
    const user: any = inject("user");
    const { visible, form, action } = toRefs(props.setting as any);
    const fileSetting = reactive({
      fileEmbed: ref(null),
      accpet: ".pdf",
      nameKey: "fileName",
      urlKey: "filePath",
    });
    const refForm = ref(null);

    // watch(visible, (v) => {
    //   if (v) {

    //   }
    // });
    onMounted(() => {
      if (!form.value) form.value = {};
      else {
        fileSetting.fileEmbed = form.value.attach;
      }
      setTimeout(() => refForm.value.clearValidate(), 1);
    });
    const save = () => {
      refForm.value.validate((valid) => {
        if (valid) {
          // if (fileSetting.fileEmbed) {
          //   form.value.attach = fileSetting.fileEmbed;
          // }
          form.value.attach = fileSetting.fileEmbed;
          emit("change", { action: action.value, data: form.value });
          visible.value = false;
        }
      });
    };

    return {
      rules,
      inputChangeEmptyToNull,
      fileSetting,
      user,
      save,
      refForm,
      visible,
      form,
    };
  },
});
