
import { ElMessage } from "element-plus";
import { isEmpty } from "@/utils/validate.js";
import { parseTime } from "@/utils/index.js";
import { sumFunc } from "@/utils/dataFormat.js";
import { defineComponent, toRefs, ref, reactive, computed, watch } from "vue";
import { useFundWatch } from "@/network/fundWatch";
import { fundWatchDoStatusDic, fundWatchSourceDic } from "@/network/lookUp.ts";

import Fileupload from "@/components/fileupload";
import { numberRulesNoRequired, numberRules } from "@/model/fundFormRules";

export default defineComponent({
  props: {
    id: { type: Number },
    disabled: { type: Boolean },
    modelValue: { type: Object },
  },
  emits: ["changed"],
  components: {
    Fileupload,
  },
  setup(props, { emit }) {
    const refForm = ref(null);
    const activeNames = ref(["1", "2", "3"]);
    const attachmentType = ref(null);

    const [isLoading, form, , saveData] = useFundWatch(
      ref(props.id),
      props.modelValue
    );

    // const form = reactive({
    //   id,
    //   year,
    //   project_Fund_Amount,
    //   currentSpend: [
    //   ],
    // });
    if (!form.currentSpend) form.currentSpend = [];
    if (form.project_Fund_Amount_Used && form.currentSpend.length == 0) {
      form.currentSpend.push({
        year: form.year,
        project_Fund_Amount: form.project_Fund_Amount,
        isEdit: true,
        money: form.project_Fund_Amount_Used,
        memo: "系统导入-已支出",
        attachments: [],
      });
    }

    const rules = {
      project_Fund_Amount_UnFinish_NoPay: numberRulesNoRequired,
      project_Fund_Amount_Return: numberRulesNoRequired,
      project_Fund_Amount_Finish_NoPay: numberRulesNoRequired,
      project_Contract_Amount: numberRulesNoRequired,
      funGetAmount: numberRules,
      currentSpend: [
        {
          message: "支出总金额不得大于下达金额！",
          trigger: "change",
          validator: (rule, value, callback) => {
            if (value && value.length > 0) {
              try {
                const sum = sumFunc(value.map((p) => p.money));
                if (sum > form.project_Fund_Amount) {
                  return callback(Error(rule.message));
                }
              } catch {
                return callback(Error("支出金额输入错误！"));
              }
            }
            callback();
          },
        },
      ],
      fundFileCode: [
        {
          required: true,
          message: "财政文号不能为空!",
          trigger: "blur",
        },
      ],
      fundGetDate: [
        {
          required: true,
          message: "资金实际到位时间不能为空!",
          trigger: "blur",
        },
      ],
      project_FundDo_Status: [
        {
          required: true,
          message: "资金执行状态不能为空!",
          trigger: "change",
        },
      ],
      fundDoSlowReason: [
        {
          required: true,
          message: "执行进度说明及整改计划!",
          trigger: "blur",
        },
      ],
    };

    const save = () => {
      return new Promise((resolve, reject) => {
        refForm.value.validate((vaild) => {
          if (vaild) {
            saveData()
              .then((p) => {
                emit("changed", p);
                resolve(p);
              })
              .catch((p) => {
                reject("资金信息填报错误！");
              });
          } else {
            reject("资金信息填报错误！");
          }
        });
      });
    };
    const confirm = () => {
      //console.log(visible);
    };
    const arraySpanMethod = ({ row, column, rowIndex, columnIndex }) => {
      if (columnIndex === 0 || columnIndex === 1) {
        if (rowIndex === 0) {
          return [form.currentSpend.length, 1];
        } else {
          return [0, 0];
        }
      } else {
        return [1, 1];
      }
    };

    const getSummaries = (param) => {
      const { columns, data } = param;
      const sums: string[] = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        }
        if (index === 1) {
          sums[index] = form["project_Fund_Amount"];
          return;
        }
        if (index != 4) {
          sums[index] = "";
          return;
        } else {
          try {
            const sum = sumFunc(data.map((item) => item[column.property]));
            sums[index] =
              sum > form.project_Fund_Amount
                ? "数据异常，支出总金额不得大于下达金额！"
                : sum.toString();
          } catch (err) {
            console.log(err);
            sums[index] = "数据异常";
          }
        }
      });

      return sums;
    };

    const add = () => {
      form.currentSpend.push({
        year: form.year,
        project_Fund_Amount: form.project_Fund_Amount,
        isEdit: true,
        attachments: [],
      });
    };

    return {
      ElMessage,
      isEmpty,
      numberRulesNoRequired,
      rules,
      refForm,
      parseTime,
      fundWatchSourceDic,
      fundWatchDoStatusDic,
      isLoading,
      getSummaries,
      arraySpanMethod,
      activeNames,
      attachmentType,
      confirm,
      form,
      add,
      save,
    };
  },
});
